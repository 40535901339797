import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const DisclaimerText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Cinzel", serif',
  color: '#ffd700',
  fontSize: '0.8rem',
  textAlign: 'center',
  marginTop: theme.spacing(4),
}));

const Disclaimer = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <DisclaimerText>
        Data is publicly available at <Link href="https://github.com/Amoner/gladpush/" target="_blank" rel="noopener noreferrer">GitHub</Link>. 
        Report issues there or on <Link href="https://www.reddit.com/r/worldofpvp/comments/1ev4329/new_tool_arena_comp_dr_viewer/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button" target="_blank" rel="noopener noreferrer">Reddit</Link>.
      </DisclaimerText>
    </Box>
  );
};

export default Disclaimer;