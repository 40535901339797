import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import TeamCompSpellMatrix from './components/TeamCompSpellMatrix';
import DRTrainingGame from './components/DRTrainingGame';
import Navigation from './components/Navigation';
import Home from './components/Home';
import Disclaimer from './components/Disclaimer';
import GoogleAnalytics from './components/GoogleAnalytics';
import spells from './spells.json';  // Import the spells data

const wowTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFD100', // WoW gold
    },
    secondary: {
      main: '#0081FF', // WoW blue
    },
    background: {
      default: '#1F1F1F', // Dark background
      paper: '#2B2B2B', // Slightly lighter for paper elements
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B0B0B0',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
    h4: {
      fontWeight: 600,
      color: '#FFD100', // Gold color for headers
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0, // Square buttons are more WoW-like
        },
        contained: {
          backgroundColor: '#4A4A4A',
          '&:hover': {
            backgroundColor: '#5A5A5A',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#FFD100', // Gold color for select icons
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#3A3A3A',
          },
          '&.Mui-selected': {
            backgroundColor: '#4A4A4A',
            '&:hover': {
              backgroundColor: '#5A5A5A',
            },
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            backgroundColor: '#1F1F1F',
            color: '#FFD100',
            fontWeight: 600,
          },
        },
      },
    },
  },
});

const GlobalStyles = createGlobalStyle`
  .wowhead-tooltip {
    z-index: 9999 !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
`;

function App() {
  const [spells, setSpells] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Fetching spells...');
    fetch('https://raw.githubusercontent.com/Amoner/gladpush/main/spells.json')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Spells data received:', data);
        if (!data.spells) {
          throw new Error('Spells data is not in the expected format');
        }
        setSpells(data.spells);
      })
      .catch(error => {
        console.error('Error fetching spells:', error);
        setError(error.toString());
      });
  }, []);

  if (error) return <div>Error loading spells: {error}</div>;
  if (spells.length === 0) return <div>Loading spells...</div>;

  return (
    <Router>
      <GlobalStyles />
      <ThemeProvider theme={wowTheme}>
        <CssBaseline />
        <div className="App">
          <GoogleAnalytics /> {/* Add this line */}
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dr-matrix" element={<TeamCompSpellMatrix spells={spells} />} />
            <Route path="/dr-training" element={<DRTrainingGame spells={spells} />} />
            <Route path="*" element={<Home />} /> {/* Catch-all route */}
          </Routes>
        </div>
        <Disclaimer />
      </ThemeProvider>
    </Router>
  );
}

export default App;