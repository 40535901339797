import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Select, MenuItem, FormControl, InputLabel, Button, Typography, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Box, Avatar, Switch, FormControlLabel, useMediaQuery, Tooltip, Grid
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const FloatingButton = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: '95%',
  transform: 'translateY(-50%)',
  zIndex: 1000,
  // Optional: Hide on small screens
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const classes = [
  "Druid", "Hunter", "Mage", "Paladin", "Priest", "Rogue", "Shaman", "Warlock", "Warrior",
  "Death Knight", "Monk", "Demon Hunter", "Evoker"
];

const classSpecData = {
  'Warrior': {
      'Arms': 'ability_warrior_savageblow',
      'Fury': 'ability_warrior_innerrage',
      'Protection': 'ability_warrior_defensivestance'
  },
  'Paladin': {
      'Holy': 'spell_holy_holybolt',
      'Protection': 'ability_paladin_shieldofthetemplar',
      'Retribution': 'spell_holy_auraoflight'
  },
  'Hunter': {
      'Beast Mastery': 'ability_hunter_bestialdiscipline',
      'Marksmanship': 'ability_hunter_focusedaim',
      'Survival': 'ability_hunter_camouflage'
  },
  'Rogue': {
      'Assassination': 'ability_rogue_deadlybrew',
      'Outlaw': 'inv_sword_30',
      'Subtlety': 'ability_stealth'
  },
  'Priest': {
      'Discipline': 'spell_holy_powerwordshield',
      'Holy': 'spell_holy_guardianspirit',
      'Shadow': 'spell_shadow_shadowwordpain'
  },
  'Shaman': {
      'Elemental': 'spell_nature_lightning',
      'Enhancement': 'spell_shaman_improvedstormstrike',
      'Restoration': 'spell_nature_magicimmunity'
  },
  'Mage': {
      'Arcane': 'spell_holy_magicalsentry',
      'Fire': 'spell_fire_firebolt02',
      'Frost': 'spell_frost_frostbolt02'
  },
  'Warlock': {
      'Affliction': 'spell_shadow_deathcoil',
      'Demonology': 'spell_shadow_metamorphosis',
      'Destruction': 'spell_shadow_rainoffire'
  },
  'Monk': {
      'Brewmaster': 'spell_monk_brewmaster_spec',
      'Mistweaver': 'spell_monk_mistweaver_spec',
      'Windwalker': 'spell_monk_windwalker_spec'
  },
  'Druid': {
      'Balance': 'spell_nature_starfall',
      'Feral': 'ability_druid_catform',
      'Guardian': 'ability_racial_bearform',
      'Restoration': 'spell_nature_healingtouch'
  },
  'Demon Hunter': {
      'Havoc': 'ability_demonhunter_specdps',
      'Vengeance': 'ability_demonhunter_spectank'
  },
  'Death Knight': {
      'Blood': 'spell_deathknight_bloodpresence',
      'Frost': 'spell_deathknight_frostpresence',
      'Unholy': 'spell_deathknight_unholypresence'
  },
  'Evoker': {
      'Devastation': 'classicon_evoker_devastation',
      'Preservation': 'classicon_evoker_preservation',
      'Augmentation': 'classicon_evoker_augmentation'
  }
};

const specs = Object.fromEntries(
  Object.entries(classSpecData).map(([className, specData]) => [
    className,
    Object.keys(specData)
  ])
);

const drCategories = [
  "root", "stun", "incapacitate", "disorient", "silence", "disarm", "knockback"
];

const drCategoryIcons = {
  "root": "spell_nature_stranglevines",
  "stun": "ability_cheapshot",
  "incapacitate": "spell_nature_polymorph",
  "disorient": "spell_shadow_mindsteal",
  "silence": "ability_priest_silence",
  "disarm": "ability_warrior_disarm",
  "knockback": "ability_hunter_burstingshot"
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.paper,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SpellIcon = styled('img')({
  width: '20px',
  height: '20px',
  marginRight: '8px',
  verticalAlign: 'middle',
});

const SpellList = styled('ul')({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
});

const SpellListItem = styled('li')(({ theme }) => ({
  marginBottom: '4px',
  display: 'flex',
  alignItems: 'center',
}));

const SpellInfo = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(0.5),
}));

const ClassSpecIcon = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  marginRight: theme.spacing(1),
}));

const MiniSpellIcon = styled('img')({
  width: '24px',
  height: '24px',
  margin: '2px',
});

const MiniSpellList = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '4px',
});

const DRCategoryIcon = styled('img')({
  width: '24px',
  height: '24px',
});

const getSpecIconUrl = (className, specName) => {
  if (classSpecData[className] && classSpecData[className][specName]) {
    const iconName = classSpecData[className][specName];
    return `https://wow.zamimg.com/images/wow/icons/large/${iconName}.jpg`;
  }
  // Fallback to the previous method if the icon is not found in classSpecData
  const formattedClass = className.toLowerCase().replace(' ', '');
  const formattedSpec = specName.toLowerCase().replace(' ', '');
  return `https://wow.zamimg.com/images/wow/icons/large/classicon_${formattedClass}_${formattedSpec}.jpg`;
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error in component:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <span>Error</span>;
    }

    return this.props.children;
  }
}

const MiniSpellView = ({ spell, handleTooltipMouseEnter, handleTooltipMouseLeave }) => (
  <ErrorBoundary>
    <Box
      component="span"
      sx={{ display: 'inline-flex', alignItems: 'center', mr: 1 }}
      onMouseEnter={handleTooltipMouseEnter}
      onMouseLeave={handleTooltipMouseLeave}
    >
      <Typography component="span" sx={{ fontSize: '0.75rem', mr: 0.5 }}>
        {spell.cooldown}s
      </Typography>
      <MiniSpellIcon
        src={`https://wow.zamimg.com/images/wow/icons/small/${spell.icon}.jpg`}
        alt={spell.name}
        data-wowhead={`spell=${spell.id}`}
      />
      <Typography component="span" sx={{ fontSize: '0.75rem', ml: 0.5 }}>
        ({spell.duration}s)
      </Typography>
    </Box>
  </ErrorBoundary>
);

const classSpecShorthand = {
  'Warrior': { 'Arms': 'WAr', 'Fury': 'WFu', 'Protection': 'WPr' },
  'Paladin': { 'Holy': 'PHo', 'Protection': 'PPr', 'Retribution': 'PRe' },
  'Hunter': { 'Beast Mastery': 'HBe', 'Marksmanship': 'HMa', 'Survival': 'HSu' },
  'Rogue': { 'Assassination': 'RA', 'Outlaw': 'RO', 'Subtlety': 'RS' },
  'Priest': { 'Discipline': 'PDi', 'Holy': 'PHo', 'Shadow': 'PSh' },
  'Shaman': { 'Elemental': 'SEl', 'Enhancement': 'SEn', 'Restoration': 'SRe' },
  'Mage': { 'Arcane': 'MAr', 'Fire': 'MFi', 'Frost': 'MFr' },
  'Warlock': { 'Affliction': 'LAf', 'Demonology': 'LDe', 'Destruction': 'LDe' },
  'Monk': { 'Brewmaster': 'MB', 'Mistweaver': 'MM', 'Windwalker': 'MW' },
  'Druid': { 'Balance': 'DBa', 'Feral': 'DFe', 'Guardian': 'DGu', 'Restoration': 'DRe' },
  'Demon Hunter': { 'Havoc': 'DH', 'Vengeance': 'DV' },
  'Death Knight': { 'Blood': 'KBl', 'Frost': 'KFr', 'Unholy': 'KUn' },
  'Evoker': { 'Devastation': 'ED', 'Preservation': 'EP', 'Augmentation': 'EA' }
};

const encodeClassSpec = (className, specName) => {
  console.log('Encoding:', { className, specName });
  if (specName === 'placeholder') {
    // Use a special code for class-only encoding
    return className.substring(0, 2).toUpperCase();
  }
  const code = classSpecShorthand[className][specName];
  console.log('Encoded to:', code);
  return code || '';
};

const decodeClassSpec = (code) => {
  console.log('Decoding:', code);
  
  for (const [className, specs] of Object.entries(classSpecShorthand)) {
    for (const [specName, shortCode] of Object.entries(specs)) {
      if (shortCode === code) {
        console.log(`Decoded ${code} to:`, { class: className, spec: specName });
        return { class: className, spec: specName };
      }
    }
  }
  
  console.warn(`Unable to decode class-spec combination: ${code}`);
  return null;
};

export default function TeamCompSpellMatrix({ spells }) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [teamSize, setTeamSize] = useState(2);
  const [team, setTeam] = useState(Array(3).fill({ class: null, spec: null }));
  const tooltipTimerRef = useRef(null);
  const [wowheadLoaded, setWowheadLoaded] = useState(false);
  const [miniView, setMiniView] = useState(isMobile);
  const [filters, setFilters] = useState({});

  // New data structure for common compositions
  const commonComps = {
    2: [
      {
        name: 'Disc Fury',
        specs: [
          { class: 'Warrior', spec: 'Fury' },
          { class: 'Priest', spec: 'Discipline' },
        ],
      },
      {
        name: 'Rogue Mage',
        specs: [
          { class: 'Mage', spec: 'Frost' },
          { class: 'Rogue', spec: 'Subtlety' },
        ],
      },
      {
        name: 'Unholy DK Disc',
        specs: [
          { class: 'Death Knight', spec: 'Unholy' },
          { class: 'Priest', spec: 'Discipline' },
        ],
      },
      {
        name: 'Feral Disc',
        specs: [
          { class: 'Druid', spec: 'Feral' },
          { class: 'Priest', spec: 'Discipline' },
        ],
      },
      {
        name: 'Disc Afflication',
        specs: [
          { class: 'Warlock', spec: 'Affliction' },
          { class: 'Priest', spec: 'Discipline' },
        ],
      },
      {
        name: 'Ret Hunter',
        specs: [
          { class: 'Paladin', spec: 'Retribution' },
          { class: 'Hunter', spec: 'Beast Mastery' },
        ],
      },
      {
        name: 'Disc Assassin',
        specs: [
          { class: 'Rogue', spec: 'Assassination' },
          { class: 'Priest', spec: 'Discipline' },
        ],
      },
      {
        name: 'Preservation Fury',
        specs: [
          { class: 'Evoker', spec: 'Preservation' },
          { class: 'Warrior', spec: 'Fury' },
        ],
      },
      {
        name: 'Disc Frost',
        specs: [
          { class: 'Priest', spec: 'Discipline' },
          { class: 'Mage', spec: 'Frost' },
        ],
      },
      {
        name: 'Resto Fury',
        specs: [
          { class: 'Shaman', spec: 'Restoration' },
          { class: 'Warrior', spec: 'Fury' },
        ],
      },
      {
        name: 'Resto Fury v2',
        specs: [
          { class: 'Druid', spec: 'Restoration' },
          { class: 'Warrior', spec: 'Fury' },
        ],
      },
    ],
    3: [
      {
        name: 'RMP',
        specs: [
          { class: 'Mage', spec: 'Frost' },
          { class: 'Rogue', spec: 'Subtlety' },
          { class: 'Priest', spec: 'Discipline' },
        ],
      },
      {
        name: 'Jungle Cleave',
        specs: [
          { class: 'Hunter', spec: 'Marksmanship' },
          { class: 'Druid', spec: 'Feral' },
          { class: 'Priest', spec: 'Discipline' },
        ],
      },
      {
        name: 'TSG',
        specs: [
          { class: 'Warrior', spec: 'Arms' },
          { class: 'Death Knight', spec: 'Unholy' },
          { class: 'Paladin', spec: 'Holy' },
        ],
      },
      {
        name: 'WLS',
        specs: [
          { class: 'Warrior', spec: 'Arms' },
          { class: 'Warlock', spec: 'Destruction' },
          { class: 'Shaman', spec: 'Restoration' },
        ],
      },
      {
        name: 'RLS',
        specs: [
          { class: 'Rogue', spec: 'Subtlety' },
          { class: 'Warlock', spec: 'Affliction' },
          { class: 'Shaman', spec: 'Restoration' },
        ],
      },
      {
        name: 'MLD',
        specs: [
          { class: 'Mage', spec: 'Frost' },
          { class: 'Warlock', spec: 'Affliction' },
          { class: 'Druid', spec: 'Restoration' },
        ],
      },
      {
        name: 'Shadowcleave',
        specs: [
          { class: 'Death Knight', spec: 'Unholy' },
          { class: 'Warlock', spec: 'Affliction' },
          { class: 'Shaman', spec: 'Restoration' },
        ],
      },
      {
        name: 'Turbo Cleave',
        specs: [
          { class: 'Warrior', spec: 'Arms' },
          { class: 'Shaman', spec: 'Enhancement' },
          { class: 'Druid', spec: 'Restoration' },
        ],
      },
      {
        name: 'God Comp',
        specs: [
          { class: 'Mage', spec: 'Frost' },
          { class: 'Priest', spec: 'Shadow' },
          { class: 'Druid', spec: 'Restoration' },
        ],
      },
      {
        name: 'Thunder Cleave',
        specs: [
          { class: 'Warrior', spec: 'Arms' },
          { class: 'Shaman', spec: 'Elemental' },
          { class: 'Paladin', spec: 'Holy' },
        ],
      },
    ],
  };

  const handleCompositionClick = (compSpecs) => {
    setTeamSize(compSpecs.length);
    setTeam((prevTeam) => {
      const newTeam = [...prevTeam];
      compSpecs.forEach((member, index) => {
        newTeam[index] = { class: member.class, spec: member.spec };
      });
      return newTeam;
    });
  };

  const updateURL = useCallback(() => {
    const params = new URLSearchParams();
    params.append('size', teamSize);
    
    const encodedTeam = team.slice(0, teamSize)
      .map(member => {
        if (member.class && member.spec) {
          return encodeClassSpec(member.class, member.spec);
        } else if (member.class) {
          // Encode just the class if spec is not selected
          return encodeClassSpec(member.class, 'placeholder');
        }
        return '';
      })
      .join('');
    
    console.log('Encoded team:', encodedTeam);
    params.append('team', encodedTeam);
  
    const newURL = `?${params.toString()}`;
    console.log('New URL:', newURL);
    if (newURL !== location.search) {
      navigate(newURL, { replace: true });
    }
  }, [team, teamSize, navigate, location.search]);
  
  const parseURL = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const size = parseInt(params.get('size'), 10) || 2;
    const encodedTeam = params.get('team') || '';
    
    console.log('Parsing URL:', { size, encodedTeam });
  
    const decodedTeam = [];
    for (let i = 0; i < encodedTeam.length; i += 3) {
      const code = encodedTeam.substr(i, 3);
      const classSpec = decodeClassSpec(code);
      console.log('Decoded class-spec:', { code, classSpec });
      if (classSpec) {
        decodedTeam.push(classSpec);
      }
    }
  
    console.log('Decoded team:', decodedTeam);
    
    setTeamSize(size);
    setTeam(prevTeam => {
      const newTeam = Array(3).fill({ class: null, spec: null });
      decodedTeam.forEach((member, index) => {
        if (member) {
          newTeam[index] = member;
        }
      });
      console.log('New team state after URL parsing:', newTeam);
      return newTeam;
    });
  }, [location.search]);
  

  useEffect(() => {
    parseURL();
  }, []); // Empty dependency array

  useEffect(() => {
    updateURL();
  }, [updateURL]);

  const resetTeam = useCallback(() => {
    setTeamSize(2);
    setTeam(Array(3).fill({ class: null, spec: null }));
  }, []);


  const handleClassChange = useCallback((index, value) => {
    console.log(`Changing class for index ${index} to ${value}`);
    setTeam(prevTeam => {
      const newTeam = [...prevTeam];
      newTeam[index] = { class: value, spec: null };
      console.log('New team state after class change:', newTeam);
      return newTeam;
    });
    updateURL();
  }, [updateURL]);

  const handleSpecChange = useCallback((index, value) => {
    console.log(`Changing spec for index ${index} to ${value}`);
    setTeam(prevTeam => {
      const newTeam = [...prevTeam];
      newTeam[index] = { ...newTeam[index], spec: value };
      console.log('New team state after spec change:', newTeam);
      return newTeam;
    });
    updateURL();
  }, [updateURL]);

  const filteredSpells = useMemo(() => {
    if (!spells || spells.length === 0) return [];
    return spells.filter(spell => 
      team.slice(0, teamSize).some(member => 
        member.class && member.spec &&
        (member.class === spell.class || spell.class === 'all') &&
        (member.spec === spell.spec || spell.spec === 'all' || spell.spec === null)
      )
    );
  }, [spells, team, teamSize]);

  const spellMatrix = useMemo(() => {
    if (!spells || spells.length === 0) return {};
    const matrix = {};
    drCategories.forEach(category => {
      matrix[category] = {};
      team.slice(0, teamSize).forEach((member, index) => {
        if (member.class && member.spec) {
          const key = `${member.class}-${member.spec}`;
          matrix[category][key] = filteredSpells.filter(spell => 
            spell.drCategory === category &&
            (spell.class === member.class || spell.class === 'all') &&
            (member.spec === spell.spec || spell.spec === 'all' || spell.spec === null)
          );
        }
      });
    });
    return matrix;
  }, [filteredSpells, team, teamSize]);

  const fadeOutTooltip = useCallback(() => {
    if (!wowheadLoaded) return;
    
    const tooltip = document.querySelector('.wowhead-tooltip');
    if (tooltip) {
      tooltip.style.opacity = '0';
      setTimeout(() => {
        if (window.$WowheadPower && typeof window.$WowheadPower.hideTooltip === 'function') {
          window.$WowheadPower.hideTooltip();
        }
      }, 500);
    }
  }, [wowheadLoaded]);


  const handleTooltipMouseEnter = useCallback((event) => {
    if (!wowheadLoaded) return;
    
    try {
      const target = event.currentTarget;
      if (window.$WowheadPower && typeof window.$WowheadPower.showTooltip === 'function') {
        window.$WowheadPower.showTooltip(event, target);

        if (tooltipTimerRef.current) {
          clearTimeout(tooltipTimerRef.current);
        }

        tooltipTimerRef.current = setTimeout(() => {
          fadeOutTooltip();
        }, 5000);
      } else {
        console.warn('Wowhead tooltip functionality not available');
      }
    } catch (error) {
      console.error('Error in handleTooltipMouseEnter:', error);
    }
  }, [wowheadLoaded, fadeOutTooltip]);

  const handleTooltipMouseLeave = useCallback(() => {
    if (!wowheadLoaded) return;
    
    if (window.$WowheadPower && typeof window.$WowheadPower.hideTooltip === 'function') {
      window.$WowheadPower.hideTooltip();
    }
    if (tooltipTimerRef.current) {
      clearTimeout(tooltipTimerRef.current);
    }
  }, [wowheadLoaded]);

  useEffect(() => {
    const loadWowheadScripts = () => {
      if (!window.$WowheadPower) {
        const whTooltips = {colorLinks: true, iconizeLinks: true, renameLinks: true};
        const script = document.createElement('script');
        script.src = '//wow.zamimg.com/widgets/power.js';
        script.async = true;
        script.onload = () => {
          if (window.$WowheadPower) {
            setWowheadLoaded(true);
            console.log('Wowhead scripts loaded successfully');
            if (typeof window.$WowheadPower.showTooltip === 'function') {
              const originalShowTooltip = window.$WowheadPower.showTooltip;
              window.$WowheadPower.showTooltip = function(e, target) {
                try {
                  originalShowTooltip.call(this, e, target);
                  const tooltip = document.querySelector('.wowhead-tooltip');
                  if (tooltip) {
                    tooltip.style.transition = 'opacity 0.5s ease-in-out';
                    tooltip.style.opacity = '1';
                  }
                } catch (error) {
                  console.error('Error in showTooltip:', error);
                }
              };
            } else {
              console.warn('Wowhead showTooltip function not found');
            }
          } else {
            console.warn('$WowheadPower not available after script load');
          }
        };
        script.onerror = (error) => {
          console.error('Error loading Wowhead script:', error);
        };
        document.body.appendChild(script);
      } else {
        setWowheadLoaded(true);
        console.log('Wowhead scripts already loaded');
      }
    };

    loadWowheadScripts();

    return () => {
      if (tooltipTimerRef.current) {
        clearTimeout(tooltipTimerRef.current);
      }
      if (wowheadLoaded && window.$WowheadPower && typeof window.$WowheadPower.hideTooltip === 'function') {
        window.$WowheadPower.hideTooltip();
      }
    };
  }, [wowheadLoaded]);

  const renderDRCategory = (category) => {
    const iconUrl = `https://wow.zamimg.com/images/wow/icons/small/${drCategoryIcons[category]}.jpg`;
    return (
      <Tooltip title={category}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <DRCategoryIcon src={iconUrl} alt={category} />
          {!miniView && <Typography variant="caption">{category}</Typography>}
          {miniView && <Typography variant="caption">{category.slice(0, 3)}</Typography>}
        </Box>
      </Tooltip>
    );
  };


  useEffect(() => {
    console.log('Team state updated:', team);
  }, [team]);

  if (!spells || spells.length === 0) {
    return <div>No spells data available</div>;
  }

  return (
    <Box sx={{ padding: 4, maxWidth: 'xl', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Team Composition Spell Matrix
      </Typography>
      
      <Box sx={{ mb: 4, display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="team-size-label">Team Size</InputLabel>
          <Select
            labelId="team-size-label"
            value={teamSize}
            onChange={(e) => setTeamSize(e.target.value)}
            label="Team Size"
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={resetTeam}>Reset Team</Button>
        <FormControlLabel
          control={<Switch checked={miniView} onChange={(e) => setMiniView(e.target.checked)} />}
          label="Mini View"
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 4 }}>
      <FloatingButton>
      <a href="https://www.buymeacoffee.com/amoner"><img src="https://img.buymeacoffee.com/button-api/?text=Pay my repair bill&emoji=🔨&slug=amoner&button_colour=5F7FFF&font_colour=ffffff&font_family=Poppins&outline_colour=000000&coffee_colour=FFDD00" /></a>
      </FloatingButton>
        {/* Main Content */}
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: 2, mb: 4 }}>
            {Array(teamSize).fill().map((_, index) => (
              <Box key={index}>
                <FormControl fullWidth sx={{ mb: 1 }}>
                  <InputLabel id={`class-label-${index}`}>{`Class ${index + 1}`}</InputLabel>
                  <Select
                    labelId={`class-label-${index}`}
                    value={team[index].class || ''}
                    onChange={(e) => {
                      console.log(`Class dropdown changed for index ${index}:`, e.target.value);
                      handleClassChange(index, e.target.value);
                    }}
                    label={`Class ${index + 1}`}
                  >
                    {classes.map(cls => (
                      <MenuItem key={cls} value={cls} onClick={() => console.log(`Clicked class: ${cls}`)}>
                        {cls}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {team[index].class && (
                  <FormControl fullWidth>
                    <InputLabel id={`spec-label-${index}`}>{`Spec ${index + 1}`}</InputLabel>
                    <Select
                      labelId={`spec-label-${index}`}
                      value={team[index].spec || ''}
                      onChange={(e) => handleSpecChange(index, e.target.value)}
                      label={`Spec ${index + 1}`}
                    >
                      {specs[team[index].class].map(spec => (
                        <MenuItem key={spec} value={spec}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {team[index].class && spec && (
                              <ClassSpecIcon
                                src={getSpecIconUrl(team[index].class, spec)}
                                alt={`${spec} icon`}
                              />
                            )}
                            {spec}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            ))}
          </Box>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: isMobile ? 'auto' : 650 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>DR Category</StyledTableCell>
                  {team.slice(0, teamSize).map((member, index) => (
                    member.class && member.spec ? (
                      <StyledTableCell key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <ClassSpecIcon
                            src={getSpecIconUrl(member.class, member.spec)}
                            alt={`${member.class} ${member.spec} icon`}
                          />
                          {!isMobile && `${member.class} - ${member.spec}`}
                        </Box>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell key={index}>Not Selected</StyledTableCell>
                    )
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {drCategories.map(category => (
                  <StyledTableRow key={category}>
                    <StyledTableCell component="th" scope="row">
                      {renderDRCategory(category)}
                    </StyledTableCell>
                    {team.slice(0, teamSize).map((member, index) => {
                      if (member.class && member.spec) {
                        const key = `${member.class}-${member.spec}`;
                        return (
                          <StyledTableCell key={index}>
                            {miniView ? (
                              <MiniSpellList>
                                {spellMatrix[category][key]?.map(spell => (
                                  <MiniSpellView
                                    key={spell.id}
                                    spell={spell}
                                    handleTooltipMouseEnter={handleTooltipMouseEnter}
                                    handleTooltipMouseLeave={handleTooltipMouseLeave}
                                  />
                                ))}
                              </MiniSpellList>
                            ) : (
                              <SpellList>
                                {spellMatrix[category][key]?.map(spell => (
                                  <SpellListItem key={spell.id}>
                                    <SpellInfo>{spell.cooldown}s</SpellInfo>
                                    <SpellIcon 
                                      src={`https://wow.zamimg.com/images/wow/icons/small/${spell.icon}.jpg`} 
                                      alt={spell.name}
                                    />
                                    <a 
                                      href={`https://www.wowhead.com/spell=${spell.id}`} 
                                      data-wowhead={`spell=${spell.id}`}
                                      onMouseEnter={handleTooltipMouseEnter}
                                      onMouseLeave={handleTooltipMouseLeave}
                                      style={{ color: theme.palette.primary.main, textDecoration: 'none', marginLeft: theme.spacing(0.5) }}
                                    >
                                      {spell.name}
                                    </a>
                                    <SpellInfo>
                                      ({spell.duration}s)
                                    </SpellInfo>
                                  </SpellListItem>
                                ))}
                              </SpellList>
                            )}
                          </StyledTableCell>
                        );
                      } else {
                        return <StyledTableCell key={index}>-</StyledTableCell>;
                      }
                    })}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Common Compositions Section */}
        <Box sx={{ width: { xs: '100%', sm: 160 } }}>
          <Typography variant="h6" gutterBottom>
            Common Comps
          </Typography>
          {commonComps[teamSize]?.map((comp, index) => (
            <Paper key={index} sx={{ mb: 2, p: 2, cursor: 'pointer' }} onClick={() => handleCompositionClick(comp.specs)}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {/* Composition Name */}
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                  {comp.name}
                </Typography>
              </Box>
              {/* Spec Icons */}
              <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                {comp.specs.map((member, idx) => (
                  <Tooltip key={idx} title={`${member.class} - ${member.spec}`}>
                    <ClassSpecIcon src={getSpecIconUrl(member.class, member.spec)} alt={`${member.class} ${member.spec}`} />
                  </Tooltip>
                ))}
              </Box>
            </Paper>
          ))}
        </Box>
      </Box>
    </Box>
  );
}